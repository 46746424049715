import Vue from "vue";

const firebaseConfig = {
  apiKey: "AIzaSyAPr_cb4q_vW33qpajSLwqTyny6DE35myE",
  authDomain: "to-autumn-limited.firebaseapp.com",
  databaseURL: "https://to-autumn-limited-default-rtdb.firebaseio.com",
  projectId: "to-autumn-limited",
  storageBucket: "to-autumn-limited.appspot.com",
  messagingSenderId: "169770653224",
  appId: "1:169770653224:web:cc098cfd52cc604131471d",
  measurementId: "G-ZYPW93L7QR"
};

Vue.mixin({
  data: function () {
    return {
      get companyName() {
        return "To Autumn Limited"; //公司名稱
      },
      get regFormDomain() {
        return "https://toautumn.com/"; 
      },
    };
  },
});

//  溫馨提示：建議使用較淺的顏色
//  歡迎回來的背景顏色
const panelColor        = "#e8f2fa"

//  溫馨提示：建議使用較深的顏色
//  側邊欄的背景顏色
const sideBarColor      = "#000"

//        /||\
//       / || \
//      |------|
//      |  ||  |
//      |  ||  |
//      |  ||  |
//      |  ||  |
//       \ || /
//  不用理 \||/
//        /||\
//       / || \
export {
    firebaseConfig,
    panelColor,
    sideBarColor
}